$black: #00142c;
$blue-grey: #7b899b;
$bg-grey: #f6f6f6;

@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-ExtraBold.woff2") format("woff2"),
    url("./fonts/Gilroy-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cera";
  src: url("./fonts/CeraPro-Medium.woff2") format("woff2"),
    url("./fonts/CeraPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  75% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes emphasis {
  0% {
    color: $blue-grey;
  }
  100% {
    color: $black;
  }
}

@keyframes beacon {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes background-pulse {
  40% {
    background: #f1f1f1;
  }

  100% {
    background: $bg-grey;
  }
}

@keyframes beacon-shadow {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(0, 20, 44, 0.2);
  }
  75% {
    box-shadow: 0px 0px 0px 12px rgba(0, 20, 44, 0.25);
  }
  100% {
    box-shadow: 0px 0px 0px 20px rgba(0, 20, 44, 0);
  }
}

@keyframes beacon-shadow-inversed {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0px 0px 0px 12px rgba(255, 255, 255, 0.25);
  }
  100% {
    box-shadow: 0px 0px 0px 20px rgba(255, 255, 255, 0);
  }
}

body,
html {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: "Gilroy";
  font-weight: 800;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}
.container {
  max-width: 100vw;
  overflow: hidden;
}
p {
  font-family: "Cera", sans-serif;
  font-weight: 500;
  color: $black;
}

.circle-action {
  border-radius: 50%;
  background: $black;
  display: flex;
  width: 3.5rem;
  height: 3.5rem;
  align-items: center;
  justify-content: center;
  transition: 0.5s;

  svg {
    width: 1.125rem;
    path {
      fill: #fff;
    }
  }
  &:hover {
    animation: beacon-shadow 0.75s ease-out infinite;
    background: #fff;
    svg {
      path {
        fill: $black;
      }
    }
  }
  &.inversed {
    background: #fff;
    svg {
      path {
        fill: $black;
      }
    }
    &:hover {
      animation: beacon-shadow-inversed 0.75s ease-out infinite;
      background: $black;
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}

.button {
  color: $black;
  font-size: 1.125rem;
  padding: 1rem 4rem 1rem 1rem;
  text-decoration: none;
  position: relative;
  background: $bg-grey;
  display: inline-block;
  transition: 0.2s;
  &::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 12px;
    right: 1rem;
    top: 1.25rem;
    background: url(img/arrow.svg) no-repeat center right;
    transition: 0.2s;
  }
  &:hover {
    // background: $black;
    // color: #fff;
    &::after {
      right: 0.75rem;
      // filter: brightness(100);
    }
  }
}

.actions {
  display: flex;
  align-items: center;

  a {
    margin-right: 1rem;
  }
  .scroll-trigger {
    display: flex;
    margin-left: 0.5rem;
    margin-top: -0.5rem;
    align-items: center;
    @media (max-width: 760px) {
      left: 89%;
    }
    svg {
      width: 1.5rem;
      animation: bounce 1s ease infinite;
      @media (max-width: 760px) {
        width: 1.5rem;
      }
    }
    span {
      font-size: 1rem;
      margin-left: 0.25rem;
      margin-top: 0.5rem;
    }
  }
}

.loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 199;
  flex-direction: column;
  transform: translate(0%, 0%);
  transition: 0.45s;
  h3 {
    color: #fff;

    font-size: 1rem;
    margin: 0;
    margin-bottom: -0.75rem;
  }
  &.false {
    transform: translate(0%, -100%);
    pointer-events: none;
  }
}

.intro {
  display: flex;
  height: 100vh;
  max-height: 100vh;

  background: $bg-grey;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    height: auto;
    max-height: none;
  }
  .headshot {
    width: 63vw;
    height: 63vw;
    max-width: 1190px;
    max-height: 1190px;
    border-radius: 50%;
    margin-top: 5rem;
    border: 1px solid #ddd;

    margin-left: -18%;
    // box-shadow: 0px 4px 80px 0px rgba(0, 0, 0, 0.07);
    position: relative;
    animation: background-pulse 3.5s ease-out infinite;
    @media (max-width: 768px) {
      margin-left: -264px;
      margin-top: -12.5rem;
      width: 560px;
      height: 560px;
      margin-bottom: -5rem;
    }
    img {
      width: 45.5vw;
      max-width: 859px;
      transform: translate(37.5%, 9.2%);
      @media (max-width: 768px) {
        width: 400px;
        transform: translate(39%, 10%);
      }
    }
    .outer-border {
      width: 100%;
      height: 100%;
      border: 1px solid #ddd;
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 50%;

      animation: beacon 3.5s ease-out infinite;
    }
  }
  // .outer-border {
  //   position: absolute;
  //   border: 1px solid #ddd;
  //   width: 1289px;
  //   height: 1289px;
  //   top: 0px;
  //   left: 0px;
  //   transform: translate(-11%, -13%);
  //   border-radius: 50%;
  //   display: none;
  // }

  .headline {
    margin-left: -3rem;
    margin-top: -2rem;
    position: relative;
    @media (max-width: 768px) {
      margin: 0;
      padding: 2.5rem;
      padding-bottom: 0;
    }
    h1 {
      font-size: 6.5vh;
      color: $black;
      width: 13ch;
      line-height: 150%;

      opacity: 0;
      animation: fadeIn 1.5s ease;
      animation-delay: 0.3s;
      animation-fill-mode: forwards;

      z-index: 10;

      margin-bottom: 3rem;
      strong {
        color: $blue-grey;
        animation: emphasis 3s ease;
        animation-delay: 1.6s;
        animation-fill-mode: forwards;
      }
      @media (max-width: 768px) {
        font-size: 2.125rem;
        margin-bottom: 2rem;
        width: auto;
      }
    }
  }

  .shapes {
    position: absolute;
    left: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    @media (max-width: 768px) {
      display: none;
    }
    img {
      position: absolute;
    }
    .O {
      top: 5%;
      right: -32px;
    }
    .M {
      top: 34%;
      right: 12%;
    }
    .A {
      top: 62%;
      right: -20px;
    }
    .R {
      top: 89%;
      right: 25%;
    }
  }
  .content-right {
    width: 50%;
    display: flex;
    height: 100vh;
    position: relative;
    flex-direction: column;

    @media (max-width: 760px) {
      width: 20%;
      position: static;
    }
    .header {
      display: flex;
      // margin-top: 5rem;
      padding: 3rem 2rem 0 0rem;
      align-items: center;
      @media (max-width: 760px) {
        padding: 0;
        margin-top: 2.75rem;
      }
      .logo {
        display: flex;
        align-items: center;
        z-index: 100;
        margin-left: -6rem;
        img {
          width: 16rem;
        }
        @media (max-width: 760px) {
          position: absolute;
          left: 2rem;
          margin-left: 0;
          img {
            width: 12.5rem;
          }
        }
        .name {
          text-transform: uppercase;
          letter-spacing: 3px;
          font-size: 0.75rem;
          color: $black;
          display: none;
          margin-left: 1rem;
          @media (max-width: 760px) {
            display: block;
          }
        }
      }
      .name {
        text-transform: uppercase;
        letter-spacing: 4px;
        font-size: 1.125rem;
        color: $black;
        @media (max-width: 760px) {
          display: none;
        }
      }
      nav {
        margin-left: auto;
        ul {
          list-style: none;
          display: flex;
          align-items: center;
          padding: 0;
          margin: 0;
          @media (max-width: 760px) {
            flex-direction: column;
          }
          li {
            a {
              font-family: "Cera", sans-serif;
              text-decoration: none;
              color: $black;
              font-size: 1rem;

              padding: 0 0.875rem;
              svg {
                width: 20px;
                height: 20px;
              }
              @media (max-width: 760px) {
                padding: 0 0.5rem;
                margin: 0.25rem 0;
                display: flex;
              }
            }
            &.mail {
              margin-top: 2px;
              svg {
                width: 18px;
              }
            }
          }
        }
      }
    }

    h1 {
      font-size: 6.4vh;
      color: $black;
      width: 13ch;
      line-height: 150%;
      margin-left: -6rem;

      opacity: 0;
      animation: fadeIn 1.5s ease;
      animation-delay: 0.3s;
      animation-fill-mode: forwards;
      position: absolute;
      z-index: 10;
      top: 45%;
      transform: translate(0px, -50%);
      strong {
        color: $blue-grey;
        animation: emphasis 3s ease;
        animation-delay: 1.6s;
        animation-fill-mode: forwards;
      }
      @media (max-width: 760px) {
        top: 13rem;
        left: 2rem;
        margin-left: 0;
        transform: translate(0%, -50%);
        text-align: left;
        font-size: 34px;
        width: 14ch;
      }
    }
    .shapes {
      .O {
        position: absolute;
        right: 50%;
        top: 20%;
        opacity: 0;
        animation: fadeIn 1.5s ease;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
        @media (max-width: 760px) {
          width: 40px;
          right: -3%;
          top: 22%;
        }
      }
      .M {
        position: absolute;
        right: 8%;
        top: 37%;
        opacity: 0;
        animation: fadeIn 1.25s ease;
        animation-delay: 0.8s;
        animation-fill-mode: forwards;
        @media (max-width: 760px) {
          top: 48%;
          right: 16%;
          width: 3rem;
        }
      }
      .A {
        position: absolute;
        right: 35%;
        top: 57%;
        opacity: 0;
        animation: fadeIn 1s ease;
        animation-delay: 1s;
        animation-fill-mode: forwards;
        @media (max-width: 960px) {
          right: 25%;
          top: 64%;
        }
        @media (max-width: 760px) {
          top: 61%;
          right: -2%;
          width: 3rem;
        }
      }
      .R {
        position: absolute;
        right: 65%;
        top: 75%;
        opacity: 0;
        animation: fadeIn 1s ease;
        animation-delay: 1.2s;
        animation-fill-mode: forwards;
        @media (max-width: 760px) {
          top: 79%;
          right: 27%;
          width: 2rem;
        }
      }
    }
  }
}

.work {
  padding-bottom: 1.5rem;
  max-width: 1440px;
  margin: 0 auto;
  & > header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10rem 0 9rem 0;
    @media (max-width: 760px) {
      padding: 9rem 1.5rem 6rem 1.5rem;
    }
    h2 {
      font-size: 4rem;
      margin: 0;
      position: relative;
      // &::before {
      //   content: "";
      //   background: url(./img/triangle_small.svg) no-repeat 0px 0px;
      //   position: absolute;
      //   left: -60px;
      //   width: 20px;
      //   height: 20px;
      // }
      // &::after {
      //   content: "";
      //   background: url(./img/circle_small.svg) no-repeat 0px 0px;
      //   position: absolute;
      //   right: -80px;
      //   bottom: 5px;
      //   width: 20px;
      //   height: 20px;
      // }
    }
    p {
      margin-top: 0.5rem;
      font-size: 1.25rem;
      text-align: center;
    }
  }
  .case {
    display: flex;
    padding: 0 4rem;
    margin-bottom: 14rem;
    align-items: center;
    &:nth-child(2n) {
      flex-direction: row-reverse;
      .case-information {
        margin-left: 0;
        margin-right: 2rem;
      }
    }
    @media (max-width: 960px) {
      flex-direction: column;
      padding: 0 1.5rem;
      align-items: flex-start;
      margin-bottom: 10rem;
      &:nth-child(2n) {
        flex-direction: column;
      }
    }
    .image {
      border: 1px solid #ddd;

      border-radius: 50%;
      padding: 2rem;
      @media (max-width: 768px) {
        padding: 1rem;
      }
      .image-background {
        border-radius: 50%;
        background: #13c892;
        overflow: hidden;
        width: 40vw;
        height: 40vw;
        box-shadow: 0px 5px 60px 0px rgba(0, 0, 0, 0.05);
        position: relative;
        max-width: 720px;
        max-height: 720px;
        @media (max-width: 960px) {
          width: 70vw;
          height: 70vw;
        }
        @media (max-width: 768px) {
          width: 80vw;
          height: 80vw;
        }
        img {
          justify-content: flex-end;

          transition: 0.3s;
          max-width: 620px;

          // transform: translate(20px, 80px);
          position: absolute;
          right: 0;
          bottom: 0;
          width: 35vw;
          @media (max-width: 960px) {
            width: 60vw;
          }
          @media (max-width: 768px) {
            width: 70vw;
          }
        }
        &.gn-case {
          background: #6fcfc3;
        }
        &.regtech-case {
          background: #55b0cd;
        }
      }
    }
    .case-information {
      margin-left: 6rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: -3rem;
      @media (max-width: 960px) {
        margin-left: 0rem;
        margin-top: 3rem;
      }
      h2 {
        font-size: 2.5rem;
        margin: 0;
      }
      h3 {
        font-size: 1.75rem;
        margin-bottom: 0.75rem;
        color: $black;
        margin-top: 1.5rem;
      }
      p {
        line-height: 190%;
        font-size: 1.25rem;
        margin-top: 0;
      }
      .tags {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 1.25rem;
        li {
          border-radius: 40px;
          border: 1px solid $black;
          padding: 0.6125rem 1.5rem;
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
          font-family: "Cera", sans-serif;
          font-size: 1rem;
          font-weight: 500;
          @media (max-width: 960px) {
            font-size: 0.875rem;
            padding: 0.5rem 1rem;
          }
        }
      }

      .introduction {
        margin-top: 1rem;
        .view-case {
          display: flex;
          color: $black;
          align-items: center;
          text-decoration: none;
          font-size: 1rem;
          .circle-action {
            margin-right: 0.75rem;
            width: 2.5rem;
            height: 2.5rem;
            svg {
              margin-left: 0.5rem;
              width: 0.875rem;
            }
          }
          &:hover {
            .circle-action {
              animation: beacon-shadow 0.75s ease-out infinite;
              background: #fff;
              svg {
                path {
                  fill: $black;
                }
              }
            }
          }
        }
        @media (max-width: 960px) {
          margin-top: 0;
        }
        .button {
          margin-top: 0.5 rem;
        }
      }
    }
  }
}

.case-container {
  max-height: 100vh;
  overflow: auto;
  margin-bottom: 3rem;
  position: relative;
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
    margin: 0;
  }
  h3 {
    font-size: 2rem;
  }
  p {
    font-size: 1.375rem;
    line-height: 190%;
  }
  article {
    max-width: 768px;
    margin: 0 auto;
    margin-bottom: 5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  section {
    padding: 5rem 2rem;
  }
  .image-section {
    background: $bg-grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 2rem;
    padding-bottom: 0rem;
    figure {
      margin: 0;
      margin-bottom: 3rem;
      figcaption {
        font-size: 0.875rem;
        color: $blue-grey;
        font-family: "Cera";
        margin-bottom: 0.5rem;
        font-weight: 500;
      }
      img {
        width: 100%;
        max-width: 960px;
        box-shadow: 0px 4px 52px 0px rgba(0, 0, 0, 0.05);
      }
    }
  }
  .close {
    background: $bg-grey;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 1.5rem;
    top: 1.5rem;
    transition: 0.2s;
    z-index: 9;
    svg {
      width: 1rem;
      fill: $black;
      transition: 0.2s;
    }
    &:hover {
      background: $black;
      svg {
        fill: #fff;
      }
    }
  }
  .case-introduction {
    .intro-wrapper {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 2rem;
      @media (max-width: 760px) {
        padding-top: 2rem;
      }
      article {
        transform: translate(0, -4rem);
        @media (max-width: 760px) {
          transform: translate(0, 0rem);
        }
      }
      &.stippl-case {
        background: url(./img/cases/stippl/background.png) no-repeat center;
        background-size: cover;
      }
    }
    .header-image {
      background: $bg-grey;
      display: flex;
      justify-content: center;
      figure {
        margin: 0;
      }
      .image-wrapper {
        padding: 0 2rem;
      }
      img {
        width: 100%;
        max-width: 960px;
        transform: translate(0, -8rem);
        box-shadow: 0px 4px 52px 0px rgba(0, 0, 0, 0.1);
        @media (max-width: 760px) {
          transform: translate(0, -2rem);
        }
      }
    }
  }
}
.cta {
  background: $black;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7rem 1.5rem 10rem 1.5rem;
  & > img {
    margin-bottom: 3rem;
  }
  h3 {
    color: #fff;
    margin: 0;
    font-size: 2.5rem;
  }
  p {
    color: #fff;
    max-width: 800px;
    font-size: 1.625rem;
    line-height: 150%;
  }
}
.white-btn {
  background: #fff;
  border-radius: 4px;
  color: $black;
  padding: 1rem 1.5rem;
  font-size: 1.25rem;
  text-decoration: none;
  transition: 0.2s;
  &:hover {
    box-shadow: inset 0 0 0 2px #fff;
    background: transparent;
    color: #fff;
  }
  &.black {
    background: $black;
    color: #fff;
    &:hover {
      box-shadow: inset 0 0 0 2px #fff;
      background: transparent;
      color: #fff;
    }
  }
}

//Case Modal
.react-router-modal__container {
  .case-modal {
    top: 0;
    left: 0;
    transform: translate(0%, 100%);
    width: 100%;
    // width: 100%;
    transition: 0.3s;
    border: none;
    height: 100%;
    background: #fff;
    // background: transparent;

    &.react-router-modal__modal--in {
      transform: translate(0%, 2rem);
    }
  }
  .react-router-modal__backdrop {
    transition: 0.3s;
    opacity: 0;
    &.react-router-modal__backdrop--in {
      opacity: 1;
    }
    &.react-router-modal__backdrop--out {
      opacity: 0;
    }
  }
}
